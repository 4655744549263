import { FC, memo, useMemo, useState } from "react"

import { Controller, useFormContext, useWatch } from "react-hook-form"

import { CustomerAlternateAddress, CustomerDetail } from "@ncs/ncs-api"
import { isUsState, tryToFormatPhone } from "@ncs/ts-utils"
import {
	AlternateShipToSelector,
	AnimatedEntrance,
	Box,
	Button,
	CheckboxFormField,
	CustomerBillToSelectorFormField,
	CustomerSelectorFormField,
	ErrorText,
	getAddressFields,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	Label,
	Paragraph,
	ParagraphList,
	PhoneInputFormField,
	retailSaleShipMethodId,
	TextareaFormField,
	TextInputFormField,
	useChangeCallback,
} from "@ncs/web-legos"

import { CreatePartOrderForm } from "../../../part-orders-util"

export interface CreatePartOrderCustomerProps {
	shipToCustomer: CustomerDetail | undefined
	customersErrorText: string | null
	recentPhoneNumbers: string[]
}

export const CreatePartOrderCustomer: FC<CreatePartOrderCustomerProps> = memo(
	({ shipToCustomer, customersErrorText, recentPhoneNumbers }) => {
		const [selectedAlternateAddress, setSelectedAlternateAddress] =
			useState<CustomerAlternateAddress | null>(null)

		const { control, setValue } = useFormContext<CreatePartOrderForm>()

		const [shipmentMethodId, selectedShipToId] = useWatch({
			name: ["shipmentMethodId", "siteId"],
			control,
		})

		// De-select the alternative address if the ship to changes.
		useChangeCallback(selectedShipToId, () => {
			setSelectedAlternateAddress(null)
			setValue("alternateAddressId", null, { shouldValidate: true })
		})

		// Whenever we switch to or away from a retail sale, clear out the bill to.
		useChangeCallback(shipmentMethodId, (newShipmentMethodId, prevShipmentMethod) => {
			if (
				newShipmentMethodId === retailSaleShipMethodId ||
				prevShipmentMethod === retailSaleShipMethodId
			) {
				setValue("billToId", "", { shouldValidate: true })
			}
		})

		const isInternationalAddress = useMemo((): boolean => {
			return isUsState(selectedAlternateAddress?.state || shipToCustomer?.state) === false
		}, [shipToCustomer?.state, selectedAlternateAddress?.state])

		// Keep the form updated. This info is needed to validate phone number (or not) when
		// order is being placed.
		useChangeCallback(
			isInternationalAddress,
			(newIsInternationalAddress) => {
				setValue("isInternationalAddress", newIsInternationalAddress)
			},
			{ callOnSetup: true }
		)

		// Set the customer's phone number.
		const handleSetPhone = (phone: string): void => {
			// Just store the phone as-is, without any validation yet.
			setValue("contactPhone", phone, {
				shouldValidate: true,
				shouldDirty: true,
			})
		}

		return (
			<>
				<HeadingDivider headingVariant="h3">1. Customer</HeadingDivider>

				<Box pl={1.5}>
					<GridContainer mb={1}>
						<GridItem xs={12} sm={6}>
							<Heading mb={0.6} bold variant="h5" icon="truck">
								Ship To
							</Heading>
							<CustomerSelectorFormField
								customerQueryParams={{
									partOrderReceivable: true,
								}}
								control={control}
								name="siteId"
								label=""
								placeholder="Ship To customer..."
								emptyValueFallback=""
								excludeBillTo
							/>
						</GridItem>

						<GridItem xs={12} sm={6}>
							<Heading mb={0.6} bold variant="h5" icon="dollar-sign">
								Bill To
							</Heading>
							{shipmentMethodId === retailSaleShipMethodId ?
								<Paragraph small color="secondary">
									<strong>Retail sale</strong> ship method selected below. Use
									Alternate Bill To.
								</Paragraph>
							:	<CustomerBillToSelectorFormField
									control={control}
									isDisabled={true} // disabled for Netsuite go live NS-2610 - glen
									name="billToId"
									customerId={selectedShipToId || null}
									noSelectionOptionText="Choose Ship To, then Bill To customer..."
									emptyValueFallback=""
									label=""
									addBillToInternal
								/>
							}
						</GridItem>

						{!!customersErrorText && (
							<GridItem xs={12}>
								<AnimatedEntrance show>
									<ErrorText>{customersErrorText}</ErrorText>
								</AnimatedEntrance>
							</GridItem>
						)}

						{!!selectedShipToId && (
							<>
								<GridItem xs={12} sm={6} mb={1}>
									<AnimatedEntrance show>
										<Label>Shipping destination</Label>
										{selectedAlternateAddress ?
											<ParagraphList
												lines={getAddressFields(selectedAlternateAddress)}
											/>
										:	<ParagraphList lines={getAddressFields(shipToCustomer)} />
										}
									</AnimatedEntrance>
								</GridItem>

								<GridItem xs={12} sm={6} mb={1}>
									<AnimatedEntrance show>
										<Controller
											control={control}
											name="alternateAddressId"
											render={({
												field: { onBlur, value, onChange: formOnChange },
												fieldState: { error },
											}) => (
												<AlternateShipToSelector
													label="Override shipping destination?"
													customerId={selectedShipToId}
													value={value}
													onChange={(newId, newAddress) => {
														formOnChange(newId)
														setSelectedAlternateAddress(
															newAddress ?? null
														)
													}}
													onBlur={onBlur}
													error={error?.message}
													nullNoSelectionValue
													fillContainer
												/>
											)}
										/>
									</AnimatedEntrance>
								</GridItem>
							</>
						)}

						<GridItem xs={12} sm={6}>
							<TextInputFormField
								control={control}
								name="purchaseOrder"
								label="Customer purchase order"
								emptyValueFallback=""
							/>
							<CheckboxFormField
								control={control}
								name="onlinePurchase"
								label="Mark as customer purchase (makes order visible in Customer Portal)"
							/>
						</GridItem>

						<GridItem xs={12} sm={6}>
							<PhoneInputFormField
								control={control}
								name="contactPhone"
								label="Delivery contact phone"
								placeholder={
									shipToCustomer ? "Delivery contact phone" : (
										"Select customer first"
									)
								}
								emptyValueFallback=""
								disableFormattingAndValidation={isInternationalAddress}
								disabled={!shipToCustomer}
							/>
						</GridItem>
						{recentPhoneNumbers.length > 0 && (
							<GridItem xs={12} sm={6} mb={1}>
								<Label>Recent phone numbers</Label>
								<Box d="flex" mt={0.35} gap={1} flexWrap="wrap">
									{recentPhoneNumbers.map((phone) => (
										<Button key={phone} onClick={() => handleSetPhone(phone)}>
											{tryToFormatPhone(phone)}
										</Button>
									))}
								</Box>
							</GridItem>
						)}
					</GridContainer>

					<GridContainer>
						<GridItem xs={12} sm={6}>
							<TextareaFormField
								control={control}
								name="invoiceComment"
								label="Comments to show on the customer's invoice"
								placeholder="Any invoice comments..."
							/>
						</GridItem>
					</GridContainer>
				</Box>
			</>
		)
	}
)

CreatePartOrderCustomer.displayName = "CreatePartOrderCustomer"
